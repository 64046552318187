<template>
    <div>
       <van-cell :title="PatientName" is-link to="/mpaycardl" value="切换健康卡成员">
      <!-- 使用 title 插槽来自定义标题 -->
      <van-icon
        slot="right-icon"
        class="iconfont"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
      />
      </van-cell>
    <van-cell>
          <template slot="title">
            <span style="color:#57c4b7; font-size:12px;">*温馨提示：使用微信医保支付后需等待系统处理，如3分钟内无法查询结果会自动退款。</span>
          </template>
        </van-cell>
         <van-cell-group @click="onclickOrder(item.orderId)" v-for="item in PaidList" :key="item + 'payl'">
        <van-cell v-bind="item">
       
          <template slot="title">
            <span style="font-size: 12px">{{ item.content }}</span>
          
            <div>
               <span style="color: #57c4b7; font-size: 12px">{{item.stateDescript}}</span>
            </div>
           
          </template>
          
          <template slot="label">
            <span style="font-size: 14px">支付时间：</span>
            <span style="color: #57c4b7; font-size: 14px">{{
              item.wxPaidTime
            }}</span>
          </template>
          <template slot="right-icon">
            <span style="color: #57c4b7; text-align: center">
              <p>¥{{ item.cost }}</p>
          
              
            </span>
          </template>
        </van-cell>
        
        
      </van-cell-group>
        
    </div>
   
</template>
<script>
import ajax from '../../lib/ajax';
// import { Toast } from 'vant';
import store from '../../store';
// var lists;
  export default {
    data() {
      return {
        PatientName:'',
        PaidList:{}
      };
    },
    
 created() {
            this.PatientName = store.getters["store/userName"];
            console.log(this.PatientName)
            this.getPaidList();
        },
    methods: {
       
       getPaidList(){
      ajax.post(`/Api/WxYBPayServices/List`,{
        
  begin: "2022-12-15T09:01:16.071Z",
  end: "2023-10-10T09:01:16.071Z",
  state: 2,
  oid: 0,
  medoid: "",
  hisRegno: "",
  username:  this.PatientName

      })
      .then((res)=>{
          console.log(res)
          this.PaidList=res.data.result;
      })
    },
       // 查看处方详细信息
    onclickOrder(orderId) {
      // store.commit("store/setRegNo", regNo);
      // store.commit("store/setSeqNo", seqNos);
      this.$router
        .replace({
          //path:'/Ordei/:OrderId',
          path: `/MorderInfos?orderId=${orderId}`,
        })
        .catch((err) => {
          console.log(err);
        });
    }, 
    }
  }
</script>
